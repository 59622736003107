<template>
   <div class="d-flex flex-column">
      <v-card color="success" class="ma-2" style="height: max-content; min-width: 200px">
         <v-toolbar color="secondary">Clientes</v-toolbar>
         <v-card-text class="px-4">
            <h2>
               <v-icon left>mdi-account-group</v-icon>
               {{ $store.state.dashboard.clientes }}
            </h2>
         </v-card-text>
      </v-card>
      <v-card color="warning" class="ma-2" style="height: max-content; min-width: 200px">
         <v-toolbar color="secondary">Reportes </v-toolbar>
         <v-card-text class="px-4">
            <h2>
               <v-icon left>mdi-clipboard-multiple</v-icon>
               {{ $store.state.dashboard.reportes }}
            </h2>
         </v-card-text>
      </v-card>
      <v-card color="error" class="ma-2" style="height: max-content; min-width: 200px">
         <v-toolbar color="secondary">Movimientos</v-toolbar>
         <v-card-text class="px-4">
            <h2>
               <v-icon left>mdi-swap-horizontal-bold</v-icon>
               {{ $store.state.dashboard.movimientos }}
            </h2>
         </v-card-text>
      </v-card>
   </div>
</template>

<script>
export default {};
</script>

<style></style>
