<template>
   <div class="d-flex flex-column pa-2" style="width: 100%">
      <Notification type="success" message="Información actualizada" ref="ok_noti" />
      <Notification type="error" message="Error" ref="error_noti" />
      <Notification type="error" message="Acceso denegado" ref="denied_notification" />
      <Evento ref="evento" @save="newEventSaved" />
      <Filtro ref="filtro" @range_changed="getEvents" />
      <Dialog ref="delete_dialog" message="¿Eliminar?" @result_ok="confirmDelete" />
      <v-snackbar v-model="snackbar" :color="snackbar_color" timeout="2000" class="d-flex">
         <v-icon right>mdi-clock-start</v-icon>
         <span style="font-weight: bold"> Inicia: </span>
         {{ snackbar_start }}
         <v-icon right>mdi-clock-end</v-icon>
         <span style="font-weight: bold"> Termina: </span>
         {{ snackbar_end }}
         <div v-if="snackbar_cliente" class="mt-2 ml-3">
            <span style="font-weight: bold"> Cliente: </span>
            {{ snackbar_cliente }}
         </div>
         <div v-if="snackbar_usuario" class="mt-2 ml-3">
            <span style="font-weight: bold"> Usuario: </span>
            {{ snackbar_usuario }}
         </div>
         <div v-if="snackbar_descripcion" class="mt-2 ml-3">
            <span style="font-weight: bold"> Descripción: </span>
            {{ snackbar_descripcion }}
         </div>
      </v-snackbar>
      <v-card width="100%">
         <v-toolbar dense color="secondary">
            <v-spacer></v-spacer>
            <v-btn icon class="ma-2" @click="$refs.calendar.prev()"><v-icon>mdi-chevron-left</v-icon></v-btn>
            <v-btn @click="changeType" small icon class="mr-2">
               <v-icon>{{ type_icon }}</v-icon>
            </v-btn>
            <div v-if="$refs.calendar">
               {{ $refs.calendar.title }}
            </div>
            <v-btn icon class="ma-2" @click="$refs.calendar.next()"><v-icon>mdi-chevron-right</v-icon></v-btn>
            <v-spacer></v-spacer>
         </v-toolbar>
         <v-card-text>
            <v-calendar
               ref="calendar"
               v-model="value"
               :weekdays="weekday"
               :type="type"
               :events="events"
               :event-overlap-mode="mode"
               :event-overlap-threshold="30"
               :event-color="getEventColor"
               @change="getEvents"
               @click:date="newEvent"
               @click:event="showEvent"
               locale="es-es"
            >
               <template v-slot:[`event`]="{ event }">
                  <div class="event">
                     <v-btn @click="deleteEvent(event)" icon x-small><v-icon x-small>mdi-close</v-icon></v-btn>
                     {{ event.name }}
                  </div>
               </template>
            </v-calendar>
         </v-card-text>
      </v-card>
      <v-card class="mt-2">
         <v-card-title>
            Agenda
            <v-icon right>mdi-calendar-multiple</v-icon>
            <v-spacer></v-spacer>
            <v-checkbox class="mr-2" v-if="!$store.getters.isSuperOrAdmin" label="Mostrar todos los eventos" v-model="showAll"></v-checkbox>
            <v-btn class="d-none d-sm-flex" @click="$refs.filtro.Show()"><v-icon left>mdi-filter</v-icon>Filtro</v-btn>
            <v-btn class="d-flex d-sm-none" @click="$refs.filtro.Show()" icon><v-icon>mdi-filter</v-icon></v-btn>
         </v-card-title>
         <v-card-text>
            <v-expansion-panels>
               <v-expansion-panel v-for="(event, index) in events" :key="index">
                  <v-expansion-panel-header :color="event.color">
                     <v-row>
                        <v-col align-self="center">
                           {{ event.name }}
                        </v-col>
                        <v-col cols="3" md="2" class="d-flex justify-end">
                           <v-btn v-if="event.id_cliente && !$store.getters.isSuperOrAdmin" icon small @click="makeReporte(event)"
                              ><v-icon small>mdi-clipboard-list-outline</v-icon></v-btn
                           >
                           <v-btn icon small @click="editEvent(event)"><v-icon small>mdi-square-edit-outline</v-icon></v-btn>
                           <v-btn icon small @click="deleteEvent(event)"><v-icon small>mdi-close</v-icon></v-btn>
                        </v-col>
                     </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content :color="event.color">
                     <v-icon right>mdi-clock-start</v-icon>
                     <span style="font-weight: bold"> Inicia: </span>
                     {{ event.start }}
                     <v-icon right>mdi-clock-end</v-icon>
                     <span style="font-weight: bold"> Termina: </span>
                     {{ event.end }}
                     <div v-if="event.cliente" class="mt-2 ml-3">
                        <span style="font-weight: bold"> Cliente: </span>
                        {{ event.cliente }}
                     </div>
                     <div v-if="event.usuario" class="mt-2 ml-3">
                        <span style="font-weight: bold"> Usuario: </span>
                        {{ event.usuario }}
                     </div>
                     <div v-if="event.descripcion" class="mt-2 ml-3">
                        <span style="font-weight: bold"> Descripción: </span>
                        {{ event.descripcion }}
                     </div>
                  </v-expansion-panel-content>
               </v-expansion-panel>
            </v-expansion-panels>
         </v-card-text>
      </v-card>
   </div>
</template>

<script>
import Evento from "./Agenda/Evento.vue";
import Filtro from "./Agenda/Filtro.vue";
import Dialog from "../Dialog.vue";
import moment from "moment";
import Notification from "../Notification.vue";
import c from "../../const";

export default {
   components: {
      Evento,
      Dialog,
      Notification,
      Filtro,
   },
   data: () => ({
      type: "month",
      type_icon: "mdi-calendar",
      mode: "stack",
      weekday: [0, 1, 2, 3, 4, 5, 6],
      value: "",
      events: [],
      eventToDelete: {},
      snackbar: false,
      snackbar_title: "",
      snackbar_color: "",
      snackbar_start: "",
      snackbar_end: "",
      snackbar_usuario: "",
      snackbar_cliente: "",
      snackbar_descripcion: "",
      showAll: true,
      options: {
         last: -5,
         next: 5,
      },
   }),
   methods: {
      async getEvents() {
         try {
            if (localStorage.getItem("agenda_options")) {
               this.options = JSON.parse(localStorage.getItem("agenda_options"));
            } else {
               this.options = {
                  last: -5,
                  next: 5,
               };
               localStorage.setItem("agenda_options", JSON.stringify(this.options));
            }
         } catch (error) {
            this.options = {
               last: -5,
               next: 5,
            };
            localStorage.setItem("agenda_options", JSON.stringify(this.options));
         }

         try {
            let res = await this.$store.dispatch("getEventos", {
               all: this.showAll,
               fecha_init: moment().add(this.options.last, "days").format("YYYY-MM-DD"),
               fecha_final: moment().add(this.options.next, "days").format("YYYY-MM-DD"),
            });
            if (res == true) {
               this.events = [];
               this.$store.state.eventos.forEach((element) => {
                  this.events.push({
                     name: element.evento,
                     start: moment(element.fecha_hora_init).format("YYYY-MM-DD HH:mm"),
                     end: moment(element.fecha_hora_final).format("YYYY-MM-DD HH:mm"),
                     color: element.color,
                     cliente: element.cliente,
                     usuario: element.usuario,
                     id_agenda: element.id_agenda,
                     id_cliente: element.id_cliente,
                     id_sub_usuario: element.id_sub_usuario,
                     descripcion: element.descripcion,
                  });
               });
            } else {
               throw res;
            }
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            this.$refs.error_noti.ShowPersistent(error);
         }
      },
      getEventColor(event) {
         return event.color;
      },
      newEvent(e) {
         this.$refs.evento.Show(e);
      },
      newEventSaved() {
         this.$refs.ok_noti.Show();
         this.getEvents();
      },
      showEvent(e) {
         this.snackbar_title = e.event.name;
         this.snackbar_color = e.event.color;
         this.snackbar_start = e.event.start;
         this.snackbar_end = e.event.end;
         this.snackbar_usuario = e.event.usuario;
         this.snackbar_cliente = e.event.cliente;
         this.snackbar_descripcion = e.event.descripcion;
         this.snackbar = true;
      },
      editEvent(event) {
         this.$refs.evento.Edit(event);
      },
      deleteEvent(event) {
         this.eventToDelete = event;
         this.$refs.delete_dialog.Show();
      },
      async confirmDelete() {
         try {
            let res = await this.$store.dispatch("deleteEvento", {
               id_agenda: this.eventToDelete.id_agenda,
            });
            if (res == true) {
               this.$refs.ok_noti.Show();
               this.getEvents();
            } else {
               throw res;
            }
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            this.$refs.error_noti.ShowPersistent(error);
         }
      },
      changeType() {
         if (this.type == "month") {
            this.type = "week";
            this.type_icon = "mdi-view-week";
         } else if (this.type == "week") {
            this.type = "day";
            this.type_icon = "mdi-view-day";
         } else {
            this.type = "month";
            this.type_icon = "mdi-calendar";
         }
      },
      async makeReporte(event) {
         let has_permiso = await this.$store.dispatch("checkPermiso", [c.PERMISOS.CONSULTAR_REPORTES]);
         if (has_permiso == true) {
            this.$router.push({
               name: "Reportes",
               query: {
                  status: "new",
                  time: new Date().getTime(),
                  id_cliente: event.id_cliente,
                  descripcion: event.descripcion,
                  horas: Math.abs(moment(event.start).diff(moment(event.end), "hours")),
               },
            });
         } else {
            this.$refs.denied_notification.Show();
         }
      },
   },
   watch: {
      showAll: function () {
         this.getEvents();
      },
   },
};
</script>

<style scoped>
.event {
   overflow: hidden;
   text-overflow: ellipsis;
}
</style>
