<template>
   <v-dialog v-model="show" max-width="600px">
      <Notification type="error" message="Error" ref="error_noti" />
      <Notification type="error" message="La fecha de inicio es incorrecta" ref="error_fecha1" />
      <Notification type="error" message="La fecha de finalizacion es incorrecta" ref="error_fecha2" />
      <Notification type="error" message="La fecha de finalizacion no esta despues de la fecha de inicio" ref="error_fecha1_after_fecha2" />
      <v-card :loading="isloading">
         <v-card-title> Filtro <v-icon right>mdi-filter</v-icon> </v-card-title>
         <v-card-text>
            <v-row>
               <v-col class="px-4 d-flex">
                  <v-range-slider color="accent" label="Rango de días" v-model="range" :max="max" :min="min" hide-details class="align-center"> </v-range-slider>
                  <v-btn icon @click="Reset"><v-icon>mdi-sync</v-icon></v-btn>
               </v-col>
            </v-row>
            <v-row>
               <v-col>
                  <v-text-field label="Desde:" :value="start" readonly></v-text-field>
               </v-col>
               <v-col>
                  <v-text-field label="Hasta" :value="end" readonly></v-text-field>
               </v-col>
            </v-row>
         </v-card-text>
         <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="Close">Cancelar</v-btn>
            <v-btn @click="Save" color="primary">Guardar</v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</template>

<script>
import Notification from "../../Notification.vue";
import moment from "moment";

export default {
   components: {
      Notification,
   },
   data: () => {
      return {
         options: {
            last: -5,
            next: 5,
         },
         min: -60,
         max: 60,
         range: [-5, 5],
         start: "",
         end: "",
         show: false,
         isloading: false,
      };
   },
   watch: {
      range: function () {
         this.Update();
      },
   },
   methods: {
      Show: function () {
         try {
            if (localStorage.getItem("agenda_options")) {
               this.options = JSON.parse(localStorage.getItem("agenda_options"));
            } else {
               this.options = {
                  last: -5,
                  next: 5,
               };
               localStorage.setItem("agenda_options", JSON.stringify(this.options));
            }

         } catch (error) {
            this.options = {
               last: -5,
               next: 5,
            };
            localStorage.setItem("agenda_options", JSON.stringify(this.options));
         }
         this.show = true;
         this.range = [this.options.last, this.options.next];
         this.Update();
      },
      Update() {
         this.start = moment().add(this.range[0], "days").format("YYYY-MM-DD") + ` [ ${this.range[0]} Dias ]`;
         this.end = moment().add(this.range[1], "days").format("YYYY-MM-DD") + ` [ ${this.range[1]} Dias ]`;
         this.options = {
            last: this.range[0],
            next: this.range[1],
         };
      },
      Save: async function () {
         localStorage.setItem("agenda_options", JSON.stringify(this.options));
         this.$emit("range_changed");
         this.Close();
      },
      Reset() {
         this.range = [-5, 5];
      },
      Close: function () {
         this.start = "";
         this.end = "";
         this.options = {
            last: -5,
            next: 5,
         };
         this.show = false;
      },
   },
};
</script>
