<template>
   <div>
      <Notification type="success" :message="'Bienvenido(a) a ' + name" ref="welcome_notification" />
      <Notification type="error" message="Acceso denegado" ref="denied_notification" />
      <v-toolbar flat color="secondary">
         <v-toolbar-title>Dashboard</v-toolbar-title>
         <v-spacer></v-spacer>
         <v-btn class="mr-2" color="primary" href="https://meet.google.com/new" target="_blank" rel="noopener noreferrer"> <v-icon left>mdi-account-group</v-icon>Reunión Meet</v-btn>
         <v-btn color="primary" @click="newReporte"> <v-icon left>mdi-plus</v-icon>Nuevo reporte </v-btn>
      </v-toolbar>
      <div class="d-flex flex-column flex-md-row" style="width: 100%">
         <Agenda />
         <Cards />
      </div>
   </div>
</template>

<script>
import Notification from "../components/Notification.vue";
import Cards from "../components/Dashboard/Cards.vue";
import Agenda from "../components/Dashboard/Agenda.vue";
import c from "../const";

export default {
   name: "Dashboard",
   components: {
      Notification,
      Cards,
      Agenda,
   },
   data: () => {
      return {
         name: c.APP_NAME,
      };
   },
   methods:{
      async newReporte() {
         let has_permiso = await this.$store.dispatch("checkPermiso", [c.PERMISOS.CONSULTAR_REPORTES]);
         if (has_permiso == true) {
            this.$router.push({
                  name: "Reportes",
                  query: {
                     status: "new",
                     time: new Date().getTime(),
                  },
               });
         } else {
            this.$refs.denied_notification.Show();
         }
      },
   },
   mounted: async function () {
      if (this.$router.currentRoute.query.status == "denied") {
         this.$refs.denied_notification.Show();
      }
      if (this.$router.currentRoute.query.status == "welcome") {
         this.$refs.welcome_notification.Show();
      }
   },
   beforeRouteUpdate(to, from, next) {
      if (to.query.status == "denied") {
         this.$refs.denied_notification.Show();
      }
      if (to.query.status == "welcome") {
         this.$refs.welcome_notification.Show();
      }
      next();
   },
};
</script>
