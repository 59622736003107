<template>
   <v-dialog v-model="show" max-width="600px">
      <Notification type="error" message="Error" ref="error_noti" />
      <Notification type="error" message="No se ha definido un color" ref="error_color" />
      <Notification type="error" message="La fecha de inicio es incorrecta" ref="error_fecha1" />
      <Notification type="error" message="La fecha de inicio debe ser mayor o igual al presente" ref="error_fecha1_today" />
      <Notification type="error" message="La fecha de finalizacion es incorrecta" ref="error_fecha2" />
      <Notification type="error" message="La fecha de finalizacion no esta despues de la fecha de inicio" ref="error_fecha1_after_fecha2" />
      <v-card :loading="isloading">
         <v-toolbar class="fixed-bar">
            <v-icon>mdi-calendar-month-outline</v-icon>
            Evento
            <v-spacer></v-spacer>
            <v-btn text class="d-none d-sm-flex" @click="close">Cancelar</v-btn>
            <v-btn text class="d-flex d-sm-none" @click="close" icon small><v-icon small>mdi-cancel</v-icon></v-btn>
            <v-btn color="primary" class="ml-2 d-none d-sm-flex" @click="save" :loading="isloading"
               ><v-icon left small>mdi-floppy</v-icon> Guardar</v-btn
            >
            <v-btn class="mx-2 d-flex d-sm-none" @click="save" small icon :loading="isloading"><v-icon small>mdi-floppy</v-icon></v-btn>
         </v-toolbar>
         <v-card-text>
            <div class="d-flex flex-column mt-2">
               <div class="d-flex flex-column flex-sm-row">
                  <div class="mr-4" style="width: 100%">
                     <v-text-field
                        v-model="name"
                        label="Nombre"
                        required
                        :error-messages="nameErrors"
                        @input="$v.name.$touch()"
                        @blur="$v.name.$touch()"
                     >
                     </v-text-field>
                     <v-datetime-picker clearText="Limpiar" okText="Aceptar" label="Empieza" v-model="start">
                        <template v-slot:dateIcon>
                           <v-icon>mdi-calendar</v-icon>
                        </template>
                        <template v-slot:timeIcon>
                           <v-icon>mdi-clock</v-icon>
                        </template>
                     </v-datetime-picker>
                     <v-datetime-picker clearText="Limpiar" okText="Aceptar" label="Termina" v-model="end">
                        <template v-slot:dateIcon>
                           <v-icon>mdi-calendar</v-icon>
                        </template>
                        <template v-slot:timeIcon>
                           <v-icon>mdi-clock</v-icon>
                        </template>
                     </v-datetime-picker>
                  </div>
                  <v-color-picker
                     :swatches="colors"
                     class="my-4"
                     v-model="color"
                     :value="color"
                     mode="hexa"
                     hide-mode-switch
                     hide-inputs
                     hide-sliders
                     hide-canvas
                     show-swatches
                     dot-size="8"
                     swatches-max-height="200"
                  ></v-color-picker>
               </div>
               <v-row>
                  <v-col cols="12" md="6">
                     <v-autocomplete
                        v-model="cliente"
                        label="Cliente"
                        :loading="search_loading"
                        :search-input.sync="search_cliente"
                        :items="search_items"
                        item-text="nombre"
                        item-value="id_cliente"
                        auto-select-first
                        return-object
                        outlined
                        ref="autocomplete"
                        persistent-hint
                        :hint="cliente_hint"
                     ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="6">
                     <v-autocomplete
                        v-model="usuario"
                        label="Usuario"
                        :loading="search_loading_usuario"
                        :search-input.sync="search_usuario"
                        :items="search_items_usuario"
                        item-text="nombre"
                        item-value="id"
                        auto-select-first
                        return-object
                        outlined
                        ref="autocomplete_usuario"
                        persistent-hint
                        :hint="usuario_hint"
                     ></v-autocomplete>
                  </v-col>
               </v-row>
               <v-row>
                  <v-col cols="12" md="8">
                     <v-textarea
                        label="Descripción"
                        rows="1"
                        :class="hint_space"
                        outlined
                        v-model="descripcion"
                        :error-messages="descripcionErrors"
                        @input="$v.descripcion.$touch()"
                        @blur="$v.descripcion.$touch()"
                        hide-details=""
                     ></v-textarea>
                  </v-col>
                  <v-col cols="12" md="4" class="d-flex justify-center align-center">
                     <v-checkbox label="Alerta de evento" v-model="alertar"></v-checkbox>
                  </v-col>
               </v-row>
            </div>
         </v-card-text>
      </v-card>
   </v-dialog>
</template>

<script>
import Notification from "../../Notification.vue";
import moment from "moment";
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";

export default {
   components: {
      Notification,
   },
   mixins: [validationMixin],
   validations: {
      name: { maxLength: maxLength(30), required },
      descripcion: { maxLength: maxLength(255) },
   },
   data: () => {
      return {
         search_cliente: "",
         search_loading: false,
         search_items: [],
         cliente: {
            id_cliente: null,
            nombre: "",
            empresa: "",
            tipo_cobro: "H",
            horas: 0,
            telefono: "",
            email: "",
         },
         search_usuario: "",
         search_loading_usuario: false,
         search_items_usuario: [],
         usuario: {
            id: null,
            nombre: "",
            username: "",
         },
         name: "",
         start: "",
         end: "",
         color: "",
         descripcion: "",
         isEdit: false,
         alertar: true,
         id_agenda: 0,
         show: false,
         isloading: false,
         colors: [
            ["#F44336FF", "#B71C1CFF", "#C62828FF", "#D32F2FFF", "#E91E63FF"],
            ["#880E4FFF", "#AD1457FF", "#C2185BFF", "#9C27B0FF", "#4A148CFF"],
            ["#6A1B9AFF", "#7B1FA2FF", "#673AB7FF", "#311B92FF", "#4527A0FF"],
            ["#512DA8FF", "#3F51B5FF", "#1A237EFF", "#283593FF", "#303F9FFF"],
            ["#2196F3FF", "#0D47A1FF", "#1565C0FF", "#1976D2FF", "#03A9F4FF"],
            ["#01579BFF", "#0277BDFF", "#0288D1FF", "#00BCD4FF", "#006064FF"],
            ["#00838FFF", "#0097A7FF", "#009688FF", "#004D40FF", "#00695CFF"],
            ["#00796BFF", "#4CAF50FF", "#1B5E20FF", "#2E7D32FF", "#388E3CFF"],
            ["#8BC34AFF", "#33691EFF", "#558B2FFF", "#689F38FF", "#CDDC39FF"],
            ["#827717FF", "#9E9D24FF", "#AFB42BFF", "#FFEB3BFF", "#F57F17FF"],
            ["#F9A825FF", "#FBC02DFF", "#FFC107FF", "#FF6F00FF", "#FF8F00FF"],
            ["#FFA000FF", "#FF9800FF", "#E65100FF", "#EF6C00FF", "#F57C00FF"],
            ["#FF5722FF", "#BF360CFF", "#D84315FF", "#E64A19FF", "#795548FF"],
            ["#3E2723FF", "#4E342EFF", "#5D4037FF", "#607D8BFF", "#263238FF"],
            ["#37474FFF", "#455A64FF", "#9E9E9EFF", "#212121FF", "#424242FF"],
         ],
      };
   },
   methods: {
      Show: function (args) {
         this.isEdit = false;
         this.start = args.date + " " + moment().format("HH:mm");
         this.end = args.date + " " + moment().add(1, "h").format("HH:mm");
         this.name = "";
         this.cliente = {
            id_cliente: null,
            nombre: "",
            empresa: "",
            tipo_cobro: "H",
            horas: 0,
            telefono: "",
            email: "",
         };
         this.usuario = {
            id: null,
            nombre: "",
            username: "",
         };
         this.descripcion = "";
         this.$v.$reset();
         this.show = true;
      },
      Edit: async function (args) {
         try {
            this.isEdit = true;
            this.id_agenda = args.id_agenda;
            this.start = args.start;
            this.end = args.end;
            this.name = args.name;
            this.usuario = {
               id: args.id_sub_usuario,
               nombre: args.usuario,
               username: "",
            };
            this.color = args.color;
            this.descripcion = args.descripcion;

            this.$v.$reset();
            this.show = true;

            this.isloading = true;
            let res = await this.$store.dispatch("getClienteByID", {
               id: args.id_cliente,
            });
            if (res) {
               this.cliente = res;
            }
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
      save: async function () {
         try {
            this.$v.$touch();
            if (this.$v.$invalid) {
               this.$refs.error_noti.Show();
               return;
            }
            if (this.color.trim().length <= 0) {
               this.$refs.error_color.Show();
               return;
            }
            if (!moment(this.start).isValid()) {
               this.$refs.error_fecha1.Show();
               return;
            }
            if (!moment(this.end).isValid()) {
               this.$refs.error_fecha2.Show();
               return;
            }
            if (!moment(this.start).isAfter(moment())) {
               this.$refs.error_fecha1_today.Show();
               return;
            }
            if (!moment(this.end).isAfter(moment(this.start))) {
               this.$refs.error_fecha1_after_fecha2.Show();
               return;
            }
            this.isloading = true;
            let res = null;
            if (this.isEdit == true) {
               res = await this.$store.dispatch("updateEvento", {
                  id_agenda: this.id_agenda,
                  nombre: this.name,
                  fecha_init: moment(this.start).format("YYYY-MM-DD HH:mm"),
                  fecha_final: moment(this.end).format("YYYY-MM-DD HH:mm"),
                  color: this.color,
                  id_cliente: this.cliente.id_cliente,
                  id_sub_usuario: this.usuario.id,
                  descripcion: this.descripcion,
                  alerta: this.alertar == true ? "S" : "N",
               });
            } else {
               res = await this.$store.dispatch("insertEvento", {
                  nombre: this.name,
                  fecha_init: moment(this.start).format("YYYY-MM-DD HH:mm"),
                  fecha_final: moment(this.end).format("YYYY-MM-DD HH:mm"),
                  color: this.color,
                  id_cliente: this.cliente.id_cliente,
                  id_sub_usuario: this.usuario.id,
                  descripcion: this.descripcion,
                  alerta: this.alertar == true ? "S" : "N",
               });
            }
            if (res == true) {
               this.$emit("save");
               this.close();
            } else {
               throw res;
            }
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
      close: function () {
         this.name = "";
         this.start = "";
         this.end = "";
         this.color = "";
         this.cliente = {
            id_cliente: null,
            nombre: "",
            empresa: "",
            tipo_cobro: "H",
            horas: 0,
            telefono: "",
            email: "",
         };
         this.usuario = {
            id: null,
            nombre: "",
            username: "",
         };
         this.show = false;
      },
      get_search_clientes: async function () {
         try {
            if (this.search_cliente) {
               if (this.search_cliente.trim().length > 0) {
                  this.search_loading = true;
                  let res = await this.$store.dispatch("getFastClientes", {
                     search: this.search_cliente,
                  });
                  if (res == true) {
                     this.search_items = this.$store.state.fast_clientes;
                  } else {
                     throw res;
                  }
               }
            }
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.search_loading = false;
         }
      },
      get_search_usuarios: async function () {
         try {
            if (this.search_usuario) {
               if (this.search_usuario.trim().length > 0) {
                  this.search_loading_usuario = true;
                  let res = await this.$store.dispatch("getFastUsuarios", {
                     search: this.search_usuario,
                  });
                  if (res == true) {
                     this.search_items_usuario = this.$store.state.fast_usuarios;
                  } else {
                     throw res;
                  }
               }
            }
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.search_loading_usuario = false;
         }
      },
   },
   watch: {
      search_cliente: function () {
         this.get_search_clientes();
      },
      search_usuario: function () {
         this.get_search_usuarios();
      },
      show: function () {
         if (this.show == false) {
            this.close();
         }
      },
   },
   computed: {
      nameErrors() {
         const errors = [];
         if (!this.$v.name.$dirty) return errors;
         !this.$v.name.required && errors.push("El nombre del evento es requerido");
         !this.$v.name.maxLength && errors.push("Debe contener maximo 30 caracteres");
         return errors;
      },
      descripcionErrors() {
         const errors = [];
         if (!this.$v.descripcion.$dirty) return errors;
         !this.$v.descripcion.maxLength && errors.push("Debe contener maximo 255 caracteres");
         return errors;
      },
      cliente_hint() {
         if (this.isEdit && this.cliente.nombre) {
            return this.cliente.nombre;
         } else {
            return "";
         }
      },
      usuario_hint() {
         if (this.isEdit && this.usuario.nombre) {
            return this.usuario.nombre;
         } else {
            return "";
         }
      },
      hint_space() {
         let s = "";
         if (this.isEdit && (this.usuario.nombre || this.cliente.nombre)) {
            s = "mt-4";
         }
         return "py-2 " + s;
      },
   },
};
</script>

<style scoped>
.fixed-bar {
   position: sticky;
   position: -webkit-sticky; /* for Safari */
   top: 0;
   z-index: 2;
}
</style>
